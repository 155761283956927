import { DashboardMetadata } from '@/modules/ta/dashboard/models/DashboardMetadata';
import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';

export class DashboardPage extends BaseEntityModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {DashboardMetadata}
   */
  metadata;

  /**
   * @var {boolean}
   */
  is_predefined;

  /**
   * @var {boolean}
   */
  is_favorite;

  /**
   * @var {boolean}
   */
  can_favorite;

  /**
   * @var {boolean}
   */
  is_in_library;

  /**
   * @var {?string}
   */
  type;

  /**
   * @var {?string}
   */
  type_display;

  /**
   * @var {?string}
   */
  entity;

  /**
   * @var {?string}
   */
  entity_display;

  /**
   * @var {string}
   */
  unique_id;

  /**
   * @var {string}
   */
  title;

  /**
   * @var {number}
   */
  display_order;

  /**
   * @var {string}
   */
  is_dynamic;

  /**
   * @var {boolean}
   */
  is_template;

  /**
   * @var {string}
   */
  icon;

  /**
   * @var {string}
   */
  icon_bg_color;

  /**
   * @var {string}
   */
  reporting_profiles;

  /**
   * @var {string}
   */
  cluster;

  /**
   * @var {string}
   */
  cluster_id;

  /**
   * @var {string}
   */
  cluster_name;

  /**
   * @var {boolean}
   */
  executive_summary_enabled;

  /**
   * @var {string}
   */
  folder_id;

  /**
   * @var {string}
   */
  thumbnail;

  /**
   * @var {number}
   */
  updated_at;

  /**
   * @var {string}
   */
  formatted_updated_at;

  /**
   * @var {number}
   */
  created_at;

  /**
   * @var {string}
   */
  formatted_created_at;

  /**
   * @var {boolean}
   */
  can_be_listed;

  /**
   * @var {array}
   */
  widget_ids;

  /**
   * @var {array}
   */
  data_sources;

  /**
   * @var {array}
   */
  reporting_profile_id;

  /**
   * @var {array}
   */
  joined_user_ids;

  /**
   * @var {string}
   */
  timegroup;

  /**
   * @var {boolean}
   */
  has_granular_permissions;

  /**
   * @var {object}
   */
  granular_access_levels;

  /**
   *
   * @param model
   */
  is_locked;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.metadata = new DashboardMetadata(model.metadata);
    this.is_predefined = model.is_predefined;
    this.is_in_library = model.is_in_library;
    this.type = model.type;
    this.type_display = model.type_display;
    this.entity = model.entity;
    this.entity_display = model.entity_display;
    this.unique_id = model.unique_id;
    this.title = model.title;
    this.display_order = model.display_order;
    this.is_dynamic = model.is_dynamic;
    this.is_template = model.is_template;
    this.is_favorite = model.is_favorite;
    this.can_favorite = model.can_favorite;
    this.icon = model.icon;
    this.icon_bg_color = model.icon_bg_color;
    this.reporting_profiles = model.reporting_profiles;
    this.cluster = model.cluster;
    this.cluster_id = model.cluster_id;
    this.cluster_name = model.cluster_name;
    this.folder_id = model.folder_id;
    this.executive_summary_enabled = model.executive_summary_enabled;
    this.thumbnail = model.thumbnail;
    this.updated_at = model.updated_at;
    this.formatted_updated_at = model.formatted_updated_at;
    this.created_at = model.created_at;
    this.formatted_created_at = model.formatted_created_at;
    this.can_be_listed = model.can_be_listed;
    this.widget_ids = model.widget_ids;
    this.data_sources = model.data_sources;
    this.reporting_profile_id = model.reporting_profile_id;
    this.joined_user_ids = model.joined_user_ids;
    this.timegroup = model.timegroup;
    this.has_granular_permissions = model.has_granular_permissions;
    this.granular_access_levels = model.granular_access_levels || {};
    this.is_locked = !!model.is_locked;
  }

  hasWidgets() {
    return !!this.widget_ids?.length;
  }

  hasGranularEditAccess() {
    if (!this.has_granular_permissions) return true;

    return this.granular_access_levels.can_be_edited;
  }

  /**
   * @param value
   */
  setIsTemplate(value) {
    this.is_template = value;
    this.can_be_deleted = !value;
    this.can_be_deleted_tooltip = value
      ? i18n.$t('This dashboard is predefined in your instance.')
      : null;
  }

  /**
   * @returns {boolean}
   */
  isDemoModeEnabled() {
    return this.metadata.enable_demo_mode;
  }
}
